import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import reportWebVitals from './reportWebVitals';
import { AppComponent } from '@app/app.component';
import { HashRouter } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

/*
function start() {
  gapi.client
    .init({
      apiKey: '475886830174-ra4rb18pajgfvg6pa67nm4aoqmqr81vt.apps.googleusercontent.com',
    })
    .then(() => {
      console.log('gaga-------------------------------------');
    });
}

gapi.load('client', start);
*/

root.render(
  <HashRouter>
    <AppComponent />
  </HashRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
