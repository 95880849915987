export const Config = {
  PersonalAccessToken: 'janbeeangeles:ATBBvQtZxYrA7QCHyvLHKJcbStAz8B199EBE',
  GoogleApi: 'AIzaSyBUt5ALZZLq2vCvFXmtOuug7x7bN1NtiLg',
  GoogleSheetId: '1wC5h6-i29u2QYfRrRUT5nsZ8K0bsGCww2FZSjFYQFLQ',
  MainRepo:
    'https://api.bitbucket.org/2.0/repositories/janbee-angeles/janbee-angeles.bitbucket.io/refs/branches/master',
  //'https://bitbucket.org/!api/2.0/repositories/pocket-guide-mh/pocket-guide-mh.bitbucket.io/refs/branches/master',
  MainRepoSrc: 'https://api.bitbucket.org/2.0/repositories/janbee-angeles/janbee-angeles.bitbucket.io/src',
  // https://bitbucket.org/!api/2.0/repositories/pocket-guide-mh/pocket-guide-mh.bitbucket.io/src/69b6b5faecfbcddb35c24e868a12420a88c2929a/src/assets/images/com.pocketguide.mh.bets.001/
};
